@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Mono:400,400i|IBM+Plex+Sans+Condensed:400,400i|IBM+Plex+Sans:100,100i,400,400i,700,700i|IBM+Plex+Serif:400,400i&display=swap);
@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Mono:400,400i|IBM+Plex+Sans+Condensed:400,400i|IBM+Plex+Sans:100,100i,400,400i,700,700i|IBM+Plex+Serif:400,400i&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Mono:400,400i|IBM+Plex+Sans+Condensed:400,400i|IBM+Plex+Sans:100,100i,400,400i,700,700i|IBM+Plex+Serif:400,400i&display=swap);
@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Mono:400,400i|IBM+Plex+Sans+Condensed:400,400i|IBM+Plex+Sans:100,100i,400,400i,700,700i|IBM+Plex+Serif:400,400i&display=swap);
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.footer {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  background-color: grey;
  color: lightgray;
  text-align: center; }
  .footer p {
    margin: 0; }
  .footer p:hover {
    color: #fa9387;
    cursor: pointer; }
  .footer a {
    text-decoration: none;
    color: inherit; }
  .footer div {
    padding: 30px 0 30px 0; }

.main-header {
  background-color: #e9ecef;
  color: #fa9387;
  font-size: 6vh;
  padding: 0 1vh 1vh calc(15px + 3vw);
  font-weight: lighter; }

.navbar {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: none;
  width: 100%;
  margin: 0;
  top: 0;
  -webkit-transition: all 4s;
  background-color: #fa9387; }
  .navbar .navbar-brand {
    padding-left: calc(2px + 3vw);
    color: #ecf0f1; }
    .navbar .navbar-brand:hover, .navbar .navbar-brand:focus {
      color: #ffbbbc; }
  .navbar .navbar-text {
    color: #ecf0f1; }
    .navbar .navbar-text a {
      color: #ffbbbc; }
      .navbar .navbar-text a:hover, .navbar .navbar-text a:focus {
        color: #ffbbbc; }
  .navbar .navbar-nav .nav-link {
    color: #ecf0f1;
    border-radius: .25rem;
    margin: 0 0.25em; }
    .navbar .navbar-nav .nav-link:not(.disabled):hover, .navbar .navbar-nav .nav-link:not(.disabled):focus {
      color: #ffbbbc; }
  .navbar .navbar-nav .nav-item.active .nav-link, .navbar .navbar-nav .nav-item.active .nav-link:hover, .navbar .navbar-nav .nav-item.active .nav-link:focus, .navbar .navbar-nav .nav-item.show .nav-link, .navbar .navbar-nav .nav-item.show .nav-link:hover, .navbar .navbar-nav .nav-item.show .nav-link:focus {
    color: #ffbbbc;
    background-color: #f07b6e; }
  .navbar .navbar-toggle {
    border-color: #f07b6e; }
    .navbar .navbar-toggle:hover, .navbar .navbar-toggle:focus {
      background-color: #f07b6e; }
    .navbar .navbar-toggle .navbar-toggler-icon {
      color: #ecf0f1; }
  .navbar .navbar-collapse,
  .navbar .navbar-form {
    border-color: #ecf0f1; }
  .navbar .navbar-link {
    color: #ecf0f1; }
    .navbar .navbar-link:hover {
      color: #ffbbbc; }

@media (max-width: 575px) {
  .navbar-expend-sm .navbar-nav .show .dropdown-menu .dropdown-item {
    color: #ecf0f1; }
    .navbar-expend-sm .navbar-nav .show .dropdown-menu .dropdown-item:hover, .navbar-expend-sm .navbar-nav .show .dropdown-menu .dropdown-item:focus {
      color: #ffbbbc; }
  .navbar-expend-sm .navbar-nav .show .dropdown-menu .dropdown-item.active {
    color: #ffbbbc;
    background-color: #f07b6e; } }

@media (max-width: 767px) {
  .navbar-expend-md .navbar-nav .show .dropdown-menu .dropdown-item {
    color: #ecf0f1; }
    .navbar-expend-md .navbar-nav .show .dropdown-menu .dropdown-item:hover, .navbar-expend-md .navbar-nav .show .dropdown-menu .dropdown-item:focus {
      color: #ffbbbc; }
  .navbar-expend-md .navbar-nav .show .dropdown-menu .dropdown-item.active {
    color: #ffbbbc;
    background-color: #f07b6e; } }

@media (max-width: 991px) {
  .navbar-expend-lg .navbar-nav .show .dropdown-menu .dropdown-item {
    color: #ecf0f1; }
    .navbar-expend-lg .navbar-nav .show .dropdown-menu .dropdown-item:hover, .navbar-expend-lg .navbar-nav .show .dropdown-menu .dropdown-item:focus {
      color: #ffbbbc; }
  .navbar-expend-lg .navbar-nav .show .dropdown-menu .dropdown-item.active {
    color: #ffbbbc;
    background-color: #f07b6e; } }

@media (max-width: 1199px) {
  .navbar-expend-xl .navbar-nav .show .dropdown-menu .dropdown-item {
    color: #ecf0f1; }
    .navbar-expend-xl .navbar-nav .show .dropdown-menu .dropdown-item:hover, .navbar-expend-xl .navbar-nav .show .dropdown-menu .dropdown-item:focus {
      color: #ffbbbc; }
  .navbar-expend-xl .navbar-nav .show .dropdown-menu .dropdown-item.active {
    color: #ffbbbc;
    background-color: #f07b6e; } }

.navbar-expend .navbar-nav .show .dropdown-menu .dropdown-item {
  color: #ecf0f1; }
  .navbar-expend .navbar-nav .show .dropdown-menu .dropdown-item:hover, .navbar-expend .navbar-nav .show .dropdown-menu .dropdown-item:focus {
    color: #ffbbbc; }

.navbar-expend .navbar-nav .show .dropdown-menu .dropdown-item.active {
  color: #ffbbbc;
  background-color: #f07b6e; }

.smooth-scroll a {
  padding-left: calc(3px + 3vw);
  width: auto; }

.smooth-scroll a:hover {
  opacity: 0.5; }

.home-content {
  background-color: #e9ecef;
  padding: 0 0 0 0;
  overflow-wrap: break-word;
  display: block; }

.home-content h1 {
  color: dimgrey; }

.row {
  padding-top: 20px; }

.anchors {
  background-color: #e9ecef;
  padding-top: 75px;
  padding-bottom: 10px;
  margin: 0; }
  .anchors .container {
    margin: 0;
    padding: 0; }

.anchors h4 {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  color: rgba(17, 19, 17, 0.76);
  padding-top: 5px; }

.anchors h3 {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  color: rgba(17, 19, 17, 0.76); }

.anchors p {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.anchors a {
  color: rgba(17, 19, 17, 0.76);
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  background: transparent;
  padding-left: 4px;
  border-right: 0.5em solid #fa9387; }

#part1 {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  display: compact;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: .10em;
  border-right: 0.15em solid #fa9387;
  -webkit-animation: typing 2s steps(50, end) 0s, blink-caret 0.75s step-end 0s infinite;
  animation: typing 2s steps(50, end) 0s, blink-caret 0.75s step-end 0s infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards; }

span {
  display: inline-flex; }

#part2 {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  opacity: 0;
  padding-right: calc(10px + 1vw);
  overflow: hidden;
  white-space: nowrap;
  border-right: 0.15em solid #fa9387;
  letter-spacing: .10em;
  -webkit-animation: typing2 2s steps(50, end) 2s, blink-caret 0.75s step-end 2s infinite;
  animation: typing2 2s steps(50, end) 2s, blink-caret 0.75s step-end 2s infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards; }

#part3 {
  color: mediumvioletred;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  opacity: 0;
  overflow: hidden;
  white-space: nowrap;
  border-right: 0.15em solid #fa9387;
  letter-spacing: .10em;
  -webkit-animation: typing3 15s steps(50, end) 4s infinite, blink-caret 0.75s step-end 4s infinite;
  animation: typing3 15s steps(50, end) 4s infinite, blink-caret 0.75s step-end 4s infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards; }

#part4 {
  color: darkgreen;
  width: 0;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  opacity: 0;
  overflow: hidden;
  white-space: nowrap;
  border-right: 0.15em solid #fa9387;
  letter-spacing: .10em;
  -webkit-animation: typing3 15s steps(50, end) 9s infinite, blink-caret 0.75s step-end 9s infinite;
  animation: typing3 15s steps(50, end) 9s infinite, blink-caret 0.75s step-end 9s infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards; }

#part5 {
  color: dodgerblue;
  width: 0;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  opacity: 0;
  overflow: hidden;
  white-space: nowrap;
  border-right: 0.15em solid #fa9387;
  letter-spacing: .10em;
  -webkit-animation: typing3 15s steps(50, end) 14s infinite, blink-caret 0.75s step-end 14s infinite;
  animation: typing3 15s steps(50, end) 14s infinite, blink-caret 0.75s step-end 14s infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards; }

.card-top {
  padding: 15px 15px 15px 15px; }

.cards {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  margin-left: 0; }
  .cards .row {
    padding-top: 0; }
  .cards h3 {
    font-size: 20px; }
  .cards h4 {
    font-size: 15px;
    padding: 0;
    margin: 0; }
  .cards p {
    margin: 0;
    padding: 10px 15px 10px 15px;
    background: lightgray; }
  .cards a {
    border: none; }

#project {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 0 20px 0;
  padding: 0;
  background: #fa9387;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); }
  #project a {
    padding: 0; }
  #project h3 {
    padding-right: 10px; }
  #project a:hover {
    opacity: 0.5; }

#contact {
  padding-bottom: 50px;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }
  #contact .btn-warning {
    background-color: #fa9387;
    border-color: #fa9387; }
  #contact .btn-warning:hover {
    background-color: #f86756;
    border-color: #f86756; }
  #contact input {
    border: none;
    background-color: #dadfe4; }
  #contact p {
    font-size: small; }

#autodata {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 0 20px 0;
  padding: 0;
  background: #87cefa;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); }

#mircom {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0;
  background: #facc87;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); }

.intro {
  display: flex;
  padding-bottom: 0;
  margin: 0; }

.skill-container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  height: 30px;
  width: 100%;
  background-color: lightgray; }

.skills {
  text-align: right;
  padding-bottom: 6px;
  padding-right: 10px;
  color: white; }

.skills:hover {
  opacity: 0.8; }

.java {
  width: 95%;
  background-color: #f07b6e; }

.css {
  width: 85%;
  background-color: lightskyblue; }

.spring {
  width: 75%;
  background-color: forestgreen; }

.docker {
  width: 75%;
  background-color: cornflowerblue; }

.react {
  width: 75%;
  background-color: darkslateblue; }

.google {
  width: 65%;
  background-color: indianred; }

.gci {
  width: 70%;
  background-color: orange; }

.sql {
  width: 85%;
  background-color: coral; }

.jenkins {
  width: 65%;
  background-color: lightpink; }

.python {
  width: 65%;
  background-color: yellowgreen; }

@-webkit-keyframes fade-in {
  from {
    opacity: 1;
    height: 0; }
  to {
    opacity: 1;
    height: 100%; } }

@keyframes fade-in {
  from {
    opacity: 1;
    height: 0; }
  to {
    opacity: 1;
    height: 100%; } }

@-webkit-keyframes typing {
  from {
    width: 0; }
  1% {
    border-right: 0.15em solid #fa9387; }
  99.9% {
    border-right: 0.15em solid #fa9387; }
  100% {
    width: 100%;
    border: none; } }

@keyframes typing {
  from {
    width: 0; }
  1% {
    border-right: 0.15em solid #fa9387; }
  99.9% {
    border-right: 0.15em solid #fa9387; }
  100% {
    width: 100%;
    border: none; } }

@-webkit-keyframes typing2 {
  from {
    width: 0; }
  1% {
    opacity: 1; }
  99.9% {
    border-right: 0.15em solid #fa9387; }
  100% {
    border: none;
    opacity: 1;
    width: 100%; } }

@keyframes typing2 {
  from {
    width: 0; }
  1% {
    opacity: 1; }
  99.9% {
    border-right: 0.15em solid #fa9387; }
  100% {
    border: none;
    opacity: 1;
    width: 100%; } }

@-webkit-keyframes typing3 {
  from {
    width: 0; }
  1% {
    opacity: 1;
    border-right: 0.15em solid #fa9387; }
  15% {
    width: 100%; }
  20% {
    width: 100%; }
  29% {
    width: 0; }
  33% {
    border-right: 0.15em solid #fa9387; }
  33.6% {
    border: none; }
  100% {
    opacity: 1;
    width: 0;
    border: none; } }

@keyframes typing3 {
  from {
    width: 0; }
  1% {
    opacity: 1;
    border-right: 0.15em solid #fa9387; }
  15% {
    width: 100%; }
  20% {
    width: 100%; }
  29% {
    width: 0; }
  33% {
    border-right: 0.15em solid #fa9387; }
  33.6% {
    border: none; }
  100% {
    opacity: 1;
    width: 0;
    border: none; } }

@-webkit-keyframes blink-caret {
  0% {
    border-color: #fa9387; }
  50% {
    border-color: transparent; }
  100% {
    border-color: #fa9387; } }

@keyframes blink-caret {
  0% {
    border-color: #fa9387; }
  50% {
    border-color: transparent; }
  100% {
    border-color: #fa9387; } }

@media only screen and (max-width: 714px) {
  .intro h1 {
    font-size: calc(12px + 3vw); } }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #e9ecef;
  display: flex;
  min-width: 50px;
  position: relative; }

html {
  scroll-behavior: smooth;
  overflow-x: hidden; }

h1 {
  color: #4b4b4b;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: normal; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

