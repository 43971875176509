@import "styles/variables";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $background-color;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 50px;
  position: relative;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

h1 {
  color: $header-font-color;
  font-family: $h1-font-family;
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}