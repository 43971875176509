@import "variables";

.footer {
  font-family: $content-font-family;
  background-color: grey;
  color: lightgray;
  text-align: center;
  p {
    margin: 0;
  }
  p:hover {
    color: $salmon;
    cursor: pointer;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  div {
    padding: 30px 0 30px 0;
  }
}
