@import "variables";

.home-content {
  background-color: $light-grey;
  padding: 0 0 0 0;
  overflow-wrap: break-word;
  display: block;
}

.home-content h1 {
  color: dimgrey;
}

.row {
  padding-top: 20px;
}

.anchors {
  background-color: $light-grey;
  padding-top: 75px;
  padding-bottom: 10px;
  margin: 0;
  .container {
    margin: 0;
    padding: 0;
  }
}

.anchors h4 {
  font-family: $content-font-family;
  color: rgba(17,19,17,0.76);
  padding-top: 5px;
}

.anchors h3 {
  font-family: $content-font-family;
  color: rgba(17,19,17,0.76);
}

.anchors p {
  font-family: $content-font-family;
}

.anchors a {
  color: rgba(17,19,17,0.76);
  font-family: $content-font-family;
  background: transparent;
  padding-left: 4px;
  border-right: .5em solid $salmon;
}

#part1 {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  display: compact;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: .10em;
  border-right: .15em solid $salmon;
  -webkit-animation: typing 2s steps(50, end) 0s, blink-caret .75s step-end 0s infinite;
  animation: typing 2s steps(50, end) 0s, blink-caret .75s step-end 0s infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

#part2 {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  opacity: 0;
  padding-right: calc(10px + 1vw);
  overflow: hidden;
  white-space: nowrap;
  border-right: .15em solid $salmon;
  letter-spacing: .10em;
  -webkit-animation: typing2 2s steps(50, end) 2s, blink-caret .75s step-end 2s infinite;
  animation: typing2 2s steps(50, end) 2s, blink-caret .75s step-end 2s infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

#part3 {
  color: mediumvioletred;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  opacity: 0;
  overflow: hidden;
  white-space: nowrap;
  border-right: .15em solid $salmon;
  letter-spacing: .10em;
  -webkit-animation: typing3 15s steps(50, end) 4s infinite, blink-caret .75s step-end 4s infinite;
  animation: typing3 15s steps(50, end) 4s infinite, blink-caret .75s step-end 4s infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

#part4 {
  color: darkgreen;
  width: 0;
  max-width: fit-content;
  opacity: 0;
  overflow: hidden;
  white-space: nowrap;
  border-right: .15em solid $salmon;
  letter-spacing: .10em;
  -webkit-animation: typing3 15s steps(50, end) 9s infinite, blink-caret .75s step-end 9s infinite;
  animation: typing3 15s steps(50, end) 9s infinite, blink-caret .75s step-end 9s infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

#part5 {
  color: dodgerblue;
  width: 0;
  max-width: fit-content;
  opacity: 0;
  overflow: hidden;
  white-space: nowrap;
  border-right: .15em solid $salmon;
  letter-spacing: .10em;
  -webkit-animation: typing3 15s steps(50, end) 14s infinite, blink-caret .75s step-end 14s infinite;
  animation: typing3 15s steps(50, end) 14s infinite, blink-caret .75s step-end 14s infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.card-top {
  padding: 15px 15px 15px 15px;
}

.cards {
  .row {
    padding-top: 0;
  }
  font-family: $content-font-family;
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 15px;
    padding: 0;
    margin: 0
  }
  p {
    margin: 0;
    padding: 10px 15px 10px 15px;
    background: lightgray;
  }
  a {
    border: none;
  }
  margin-left: 0;
}

#project {
  height: fit-content;
  a {
    padding: 0;
  }
  h3 {
    padding-right: 10px;
  }
  a:hover {
    opacity: 0.5;
  }
  margin: 0 0 20px 0;
  padding: 0;
  background: $salmon;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

#contact {
  padding-bottom: 50px;
  font-family: $content-font-family;
  .btn-warning {
    background-color: $salmon;
    border-color: $salmon;
  }
  .btn-warning:hover {
    background-color: darken($salmon, 10%);
    border-color: darken($salmon, 10%);
  }
  input {
    border: none;
    background-color: darken($background-color, 5%);
  }
  p {
    font-size: small;
  }
}

#autodata {
  height: fit-content;
  margin: 0 0 20px 0;
  padding: 0;
  background: $light-blue;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

#mircom {
  height: fit-content;
  padding: 0;
  background: $light-beige;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.intro {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 0;
  margin: 0;
}

.skill-container {
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 30px;
  width: 100%;
  background-color: lightgray;
}

.skills {
  text-align: right;
  padding-bottom: 6px;
  padding-right: 10px;
  color: white;
}

.skills:hover {
  opacity: 0.8;
}

.java {
  width: 95%;
  background-color: #f07b6e;
}

.css {
  width: 85%;
  background-color: lightskyblue;
}

.spring {
  width: 75%;
  background-color: forestgreen;
}

.docker {
  width: 75%;
  background-color: cornflowerblue;
}

.react {
  width: 75%;
  background-color: darkslateblue;
}

.google {
  width: 65%;
  background-color: indianred;
}

.gci {
  width: 70%;
  background-color: orange;
}

.sql {
  width: 85%;
  background-color: coral;
}

.jenkins {
  width: 65%;
  background-color: lightpink;
}

.python {
  width: 65%;
  background-color: yellowgreen;
}

@-webkit-keyframes fade-in {
  from {
    opacity: 1;
    height: 0;
  }
  to {
    opacity: 1;
    height: 100%;
  }
}

@keyframes fade-in {
  from {
    opacity: 1;
    height: 0;
  }
  to {
    opacity: 1;
    height: 100%;
  }
}

@-webkit-keyframes typing {
  from { width: 0; }
  1% { border-right: .15em solid $salmon; }
  99.9% { border-right: .15em solid $salmon; }
  100%{ width:100%; border: none; }
}

@keyframes typing {
  from { width: 0; }
  1% { border-right: .15em solid $salmon; }
  99.9% { border-right: .15em solid $salmon; }
  100%{ width:100%; border: none; }
}

@-webkit-keyframes typing2 {
  from { width: 0; }
  1% { opacity: 1; }
  99.9% { border-right: .15em solid $salmon; }
  100%{ border: none; opacity: 1; width: 100%; }
}

@keyframes typing2 {
  from { width: 0; }
  1% { opacity: 1; }
  99.9% { border-right: .15em solid $salmon; }
  100%{ border: none; opacity: 1; width: 100%; }
}

@-webkit-keyframes typing3 {
  from { width: 0; }
  1% { opacity: 1; border-right: .15em solid $salmon; }
  15% { width: 100%; }
  20% { width: 100%; }
  29% { width: 0; }
  33% { border-right: .15em solid $salmon; }
  33.6% { border: none; }
  100%{ opacity: 1; width: 0; border: none; }
}

@keyframes typing3 {
  from { width: 0; }
  1% { opacity: 1; border-right: .15em solid $salmon; }
  15% { width: 100%; }
  20% { width: 100%; }
  29% { width: 0; }
  33% { border-right: .15em solid $salmon; }
  33.6% { border: none; }
  100%{ opacity: 1; width: 0; border: none; }
}

@-webkit-keyframes blink-caret {
  0% { border-color: $salmon; }
  50% { border-color: transparent; }
  100% { border-color: $salmon; }
}

@keyframes blink-caret {
  0% { border-color: $salmon; }
  50% { border-color: transparent; }
  100% { border-color: $salmon; }
}

@media only screen and (max-width: 714px) {
  .intro h1 {
    font-size: calc(12px + 3vw);
  }
}