@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Mono:400,400i|IBM+Plex+Sans+Condensed:400,400i|IBM+Plex+Sans:100,100i,400,400i,700,700i|IBM+Plex+Serif:400,400i&display=swap');

// Colours
$light-blue: #87cefa;
$light-beige: #facc87;
$salmon: #fa9387;
$grey: #4b4b4b;
$light-grey: #e9ecef;

// Static Vars
$background-color: $light-grey;
$header-font-color: $grey;
$h1-font-family: 'IBM Plex Sans', sans-serif;
$content-font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;