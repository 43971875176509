$bgDefault      : #fa9387;
$bgHighlight    : #f07b6e;
$colDefault     : #ecf0f1;
$colHighlight   : #ffbbbc;
$dropDown       : false;
.navbar {
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: none;
  width: 100%;
  margin: 0;
  top: 0;
  -webkit-transition: all 4s;
  background-color: $bgDefault;
  .navbar-brand {
    padding-left: calc(2px + 3vw);
    color: $colDefault;
    &:hover, &:focus {
      color: $colHighlight; }}
  .navbar-text {
    color: $colDefault;
    a {
      color: $colHighlight;
      &:hover, &:focus {
        color: $colHighlight; }}}
  .navbar-nav {
    .nav-link {
      color: $colDefault;
      border-radius: .25rem;
      margin: 0 0.25em;
      &:not(.disabled) {
        &:hover, &:focus {
          color: $colHighlight; }}}
    @if ($dropDown) {
      .dropdown-menu {
        background-color: $bgDefault;
        border-color: $bgHighlight;
        .dropdown-item {
          color: $colDefault;
          &:hover, &:focus, &.active {
            color: $colHighlight;
            background-color: $bgHighlight; }}
        .dropdown-divider {
          border-top-color: $bgHighlight; }}}
    .nav-item.active, .nav-item.show {
      .nav-link, .nav-link:hover, .nav-link:focus {
        color: $colHighlight;
        background-color: $bgHighlight; }}}
  .navbar-toggle {
    border-color: $bgHighlight;
    &:hover, &:focus {
      background-color: $bgHighlight; }
    .navbar-toggler-icon {
      color: $colDefault; }}
  .navbar-collapse,
  .navbar-form {
    border-color: $colDefault; }
  .navbar-link {
    color: $colDefault;
    &:hover {
      color: $colHighlight; }}}

@media (max-width: 575px) {
  .navbar-expend-sm .navbar-nav .show .dropdown-menu {
    .dropdown-item {
      color: $colDefault;
      &:hover, &:focus {
        color: $colHighlight; }}
    .dropdown-item.active {
      color: $colHighlight;
      background-color: $bgHighlight; }}}

@media (max-width: 767px) {
  .navbar-expend-md .navbar-nav .show .dropdown-menu {
    .dropdown-item {
      color: $colDefault;
      &:hover, &:focus {
        color: $colHighlight; }}
    .dropdown-item.active {
      color: $colHighlight;
      background-color: $bgHighlight; }}}

@media (max-width: 991px) {
  .navbar-expend-lg .navbar-nav .show .dropdown-menu {
    .dropdown-item {
      color: $colDefault;
      &:hover, &:focus {
        color: $colHighlight; }}
    .dropdown-item.active {
      color: $colHighlight;
      background-color: $bgHighlight; }}}

@media (max-width: 1199px) {
  .navbar-expend-xl .navbar-nav .show .dropdown-menu {
    .dropdown-item {
      color: $colDefault;
      &:hover, &:focus {
        color: $colHighlight; }}
    .dropdown-item.active {
      color: $colHighlight;
      background-color: $bgHighlight; }}}

.navbar-expend .navbar-nav .show .dropdown-menu {
  .dropdown-item {
    color: $colDefault;
    &:hover, &:focus {
      color: $colHighlight; }}
  .dropdown-item.active {
    color: $colHighlight;
    background-color: $bgHighlight; }}

.smooth-scroll a {
  padding-left: calc(3px + 3vw);
  width: auto;
}

.smooth-scroll a:hover {
  opacity: 0.5;
}